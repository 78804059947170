import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import {
	createMigrate,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist'
import { migrations } from './redux/migrations/v2'
import bookingReducer from './slices/bookingSlice.js'
import HomeReducer from './slices/HomeSlice.js'
import rootReducer from './slices/rootSlice.js'
import searchReducer from './slices/searchSlice.js'
import newsReducer from './slices/newsSlice.js'
import dashboardReducer from './slices/dashboardSlice.js'
import adminDashboardReducer from './slices/adminDashboardSlice.js'
import memberDashboardReducer from './slices/memberDashboardSlice.js'
import locationReducer from './slices/locationSlice'

const allReducers = combineReducers({
	Home: HomeReducer,
	root: rootReducer,
	booking: bookingReducer,
	search: searchReducer,
	news: newsReducer,
	dashboard: dashboardReducer,
	adminDashboard: adminDashboardReducer,
	memberDashboard: memberDashboardReducer,
	location: locationReducer
})

const reducers = (state, action) => {
	//This is an action created to clear the entire redux store. It could be used
	// in the case when a user logs out
	if (action.type === 'store/reset') {
		return allReducers(undefined, action)
	}

	return allReducers(state, action)
}

const persistConfig = {
	key: 'stufos',
	version: 60,
	storage,
	migrate: createMigrate(migrations, { debug: false })
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {},
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
})
