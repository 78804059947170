import { palette } from '../mui.config'
import {
	Archive,
	Armchair,
	Buildings,
	CookingPot,
	HouseLine,
	PersonSimpleBike,
	RainbowCloud,
	TShirt
} from '@phosphor-icons/react'

const firebaseEventTypes = {
	selectContent: 'select_content',
	pageLoad: 'page_load',
	mapLoad: 'map_load',
	dealCreated: 'deal_created'
}

const firebaseEvents = {
	geolocationPermissionState: 'geolocation_permission_state',
	pageError: 'page_error'
}

const CONTRACT = {
	STATUS: {
		ACTIVE: {
			id: 0,
			name: 'ACTIVE',
			name_desc: 'On-time',
			desc: 'Member is actively renting',
			color: palette.primary['success-bg'],
			textColor: palette.primary.success
		},
		INACTIVE: {
			id: 1,
			name: 'INACTIVE',
			name_desc: 'Moved out',
			desc: 'Member has moved out',
			color: palette.primary['light-grey'],
			textColor: palette.primary.charcoal
		},
		BEHIND_ON_PAYMENT: {
			id: 2,
			name: 'BEHINDONPAYMENT',
			name_desc: 'Late',
			desc: 'Member is behind on payment',
			color: palette.primary['error-bg'],
			textColor: palette.primary.error
		},
		CANCELLED: {
			id: 3,
			name: 'CANCELLED',
			name_desc: 'Canceled',
			desc: 'Member has cancelled but has not moved out yet',
			color: palette.primary['light-grey'],
			textColor: palette.primary.charcoal
		},
		RESERVED: {
			id: 4,
			name: 'RESERVED',
			name_desc: 'Scheduled',
			desc: 'Member has booked but not moved in yet',
			color: palette.primary['bright-grey'],
			textColor: palette.primary['dark-blue']
		},
		PENDING_APPROVAL: {
			id: 5,
			name: 'PENDINGAPPROVAL',
			name_desc: 'Pending Approval'
		}
	},
	TERMINATION_CODES: [
		{
			id: 1,
			admin_description: 'Member no longer needs unit',
			member_description: `I don't need storage anymore`,
			org_id: 1
		},
		{
			id: 4,
			admin_description: 'Current price is too high',
			member_description: `I've decided to stop using self storage due to the cost`,
			org_id: 1
		},
		{
			id: 12,
			admin_description: 'Seasonal - Other',
			member_description: 'I only needed storage for a few days/weeks',
			org_id: 1
		},
		{
			id: 11,
			admin_description: 'Seasonal - College',
			member_description: 'I was just storing my things for the summer',
			org_id: 1
		},
		{
			id: 2,
			admin_description: 'Member going to competitor',
			member_description:
				'I found another storage facility that better suits my needs',
			org_id: 1
		},
		{
			id: 3,
			admin_description: 'Member access issues',
			member_description: 'I was not able to access my unit consistently',
			org_id: 1
		},
		{
			id: 5,
			admin_description: 'Facility Safety',
			member_description: 'I am concerned for my safety in the facility',
			org_id: 1
		},
		{
			id: 13,
			admin_description: 'Price Shopping',
			member_description:
				'I found another storage facility with better pricing',
			org_id: 1
		},
		{
			id: 14,
			admin_description: 'Damage or Loss',
			member_description: 'I experienced damage to my belongings',
			org_id: 1
		},
		{
			id: 9,
			admin_description: 'Stuf price increase',
			member_description: 'I am dissatisfied with a recent price increase',
			org_id: 1
		},
		{
			id: 15,
			admin_description: 'Area Safety',
			member_description:
				'I am concerned for the security of my belongings in the neighborhood',
			org_id: 1
		},
		{
			id: 6,
			admin_description: 'Other',
			member_description:
				'I was otherwise dissatisfied with my experience with Stuf',
			org_id: 1
		},
		{
			id: 7,
			admin_description: 'Eviction - Behavioral',
			member_description: 'admin',
			org_id: 1
		},
		{
			id: 8,
			admin_description: 'Eviction - Non Payment',
			member_description: 'admin',
			org_id: 1
		},
		{
			id: 10,
			admin_description: 'Location Closure',
			member_description: 'admin',
			org_id: 1
		},
		{
			id: 16,
			admin_description: 'Neighbor Churn',
			member_description: 'admin'
		}
	],
	CANCELLATION_MIN_DAYS: 15
}

const FACILITY = {
	FEATURES: {
		BOOKING_FLOW_APPROVAL: {
			id: 10,
			org_id: 1,
			name: 'booking_flow_approval',
			description:
				'Category: General. StufOS booking flow approval per facility'
		},
		ADDRESS_VERIFICATION: {
			id: 11
		}
	},
	STATUS: [
		{ id: 0, name: 'CLOSED' },
		{ id: 1, name: 'BUILDOUT' },
		{ id: 2, name: 'RAMPING' },
		{ id: 3, name: 'STABILIZED' }
	]
}

const UNIT = {
	BOOKING: {
		NBR_DAYS_ADVANCE: 7
	},
	SIZE_OPTIONS: {
		1: {
			id: '1',
			img: '/unit-size-small.png',
			imgStyle: { height: 'auto', width: '85px' },
			name: 'Small',
			name_desc: 'Less than 4x6',
			desc: 'Perfect for an extra closet, seasonal storage, small business storage',
			type: 'unit'
		},
		2: {
			id: '2',
			img: '/unit-size-medium.png',
			imgStyle: { height: 'auto', width: '105px' },
			name: 'Medium',
			name_desc: '4x6 to 6x9',
			desc: 'Perfect for moving a 1 bedroom, business inventory, sports, and leisure equipment',
			type: 'unit'
		},
		3: {
			id: '3',
			img: '/unit-size-large.png',
			imgStyle: { height: 'auto', width: '115px' },
			name: 'Large',
			name_desc: '6x10 and above',
			desc: 'Long-term storage, business equipment, moving 1-2 bedrooms',
			type: 'unit'
		},
		10: {
			id: '10',
			img: '/parking.svg',
			imgStyle: { height: 'auto', width: '10px' },
			name: 'Parking',
			name_desc: 'Monthly or long term parking',
			desc: 'Monthly or long term parking for your car / suv / van',
			type: 'parking'
		}
	},
	STATUS: {
		STANDARD: 1,
		STRATEGICHOLD: 2,
		TEMPORARYHOLD: 3,
		PERMANENTLYUNSELLABLE: 4,
		STUFITCLOSET: 5,
		DECOMMISSIONED: 6,
		OTHER: 7,
		MAINTENANCEUNIT: 8,
		CLOSEDLOCATION: 9
	},
	STATUS_CODES: {
		1: 'Standard',
		2: 'Strategic Hold',
		3: 'Temporary Hold',
		4: 'Permanently Unsellable',
		5: 'Stuf IT Closet',
		6: 'Decommissioned',
		7: 'Other',
		8: 'Maintenance Unit',
		9: 'Closed Location'
	}
}

const companyInfo = {
	phone: '(833) 380-7883',
	email: 'info@stufstorage.com',
	social: {
		twitter: 'stufstorage',
		facebook: 'storemystuf',
		instagram: 'stuf_storage',
		appStoreUrl: 'https://apps.apple.com/app/id1552261485',
		linkedin: 'stufstorage'
	},
	links: {
		addFacilityCSVTemplate:
			'https://docs.google.com/spreadsheets/d/1MZMheIc0cOWZr1MtPZE-yk0gjvFa5lHoO9qW-dYwrKg/edit?usp=sharing',
		addUnitCSVTemplate:
			'https://docs.google.com/spreadsheets/d/1x6J-yK-6ZZk8TCcRZXNw-kI2d0p625yMp-NncV3NAmQ/edit#gid=0'
	}
}

const HEADER = {
	withNav: 'withNav',
	withoutNav: 'withoutNav'
}

const SORT = [
	{
		name: 'price',
		value: 0
	},
	{
		name: 'distance',
		value: 1
	}
]

const ICON_MAP = {
	'first-floor': '/icon-first-floor.svg',
	phone: '/icon-phone.svg',
	send: '/icon-send.svg',
	clock: '/icon-clock.svg',
	'climate-controlled': '/icon-climate.svg',
	climate: '/icon-climate.svg',
	cart: '/icon-cart.svg',
	'full-height': '/icon-full-height.svg',
	'mobile-app': '/icon-mobile-app.svg',
	garage: '/icon-garage.svg',
	parking: '/icon-parking.svg',
	elevator: '/icon-elevator.svg'
}

const FAQ = {
	General: {
		subgroups: [
			{
				id: 1,
				header: 'How do I get access to the facility?',
				description:
					'Once you complete your reservation and booking, the Member Experience team will reach out to you with details on how to access the Stuf facility.'
			},
			{
				id: 2,
				header: 'What are the facility hours?',
				description:
					'Access hours vary for each location, please refer to your Member Experience card.'
			},
			{
				id: 3,
				header: "I'm having trouble with the Stuf mobile app",
				description: `Try logging out of the app and log back in. If you are still having trouble, call or text us at ${companyInfo.phone}.`
			},
			{
				id: 4,
				header: 'How do I reset my lock?',
				description: `We have a helpful video for that! <a class='underline' href=''>Click here.</a>`
			},
			{
				id: 5,
				header: 'How do I get in contact with the Member Experience team?',
				description: `Our member experience team hours are 11am - 7pm EST. Call or text us at ${companyInfo.phone}.`
			},
			{
				id: 6,
				header: 'Where can I find my billing information?',
				description: `You can manage your billing and payment information on <a class='underline' href='https://www.neighbor.com' target="_blank" rel="noopener noreferrer">neighbor.com</a>`
			}
		]
	},
	'Moving Help': {
		subgroups: [
			{
				id: 5,
				header:
					'Do you offer a moving service to pick up and move in my things?',
				description: `No, we don't provide moving services. However, in many cities, we partner with moving companies to provide a seamless moving and storage experience. Call or text us at ${companyInfo.phone} to learn more.`
			},
			{
				id: 6,
				header:
					'Are there carts or hand trucks at the facility to help me move in?',
				description: `There is a hand truck located near the entrance door. Feel free to use it!`
			}
		]
	},
	'Helpful Hints': {
		subgroups: [
			{
				id: 7,
				header: "Where do I park once I'm at the facility?",
				description: `Our parking locations vary depending on the facility. You will receive details about parking shortly after your booking.`
			}
		]
	}
}

const STYLES = {
	INPUT: {
		style:
			'w-full relative border rounded-md form-control block py-3 px-4 text-left mr-3 text-base appearance-none focus:outline-none focus-within:border-brand-charcoal-light bg-brand-white',
		labelStyle:
			'font-bold uppercase text-brand-charcoal-dark mb-2 tracking-wide w-full'
	},
	roundedGreen:
		'font-bold uppercase rounded-full bg-brand-confirmation-green-bg text-brand-confirmation-green py-2 px-3',
	roundedRed:
		'font-bold uppercase rounded-full bg-brand-alert-red-bg text-brand-alert-red py-2 px-3',
	roundedBlue:
		'font-bold uppercase rounded-full bg-brand-light-blue-1 text-brand-dark-blue py-2 px-3',
	roundedGrey:
		'font-bold uppercase rounded-full bg-brand-light-grey-2 text-brand-dark-grey-2 py-2 px-3'
}

const PROMOTIONS = {
	TYPES: {
		percentageOff: '1',
		flatPrice: '2'
	}
}

const DISCOUNT = {
	TYPES: {
		flatPrice: 0,
		percentageOff: 1
	}
}

const ACCESS = {
	NONE: 0,
	BRIVO: 1,
	OPENPATH: 2,
	SEAM: 3
}

const ORG = {
	STUF: 1
}

const STATES = [
	{ name: 'Alabama', abbr: 'AL', state_id: 1 },
	{ name: 'Alaska', abbr: 'AK', state_id: 2 },
	{ name: 'Arizona', abbr: 'AZ', state_id: 4 },
	{ name: 'Arkansas', abbr: 'AR', state_id: 5 },
	{ name: 'California', abbr: 'CA', state_id: 6 },
	{ name: 'Colorado', abbr: 'CO', state_id: 7 },
	{ name: 'Connecticut', abbr: 'CT', state_id: 8 },
	{ name: 'Delaware', abbr: 'DE', state_id: 9 },
	{ name: 'Florida', abbr: 'FL', state_id: 11 },
	{ name: 'Georgia', abbr: 'GA', state_id: 12 },
	{ name: 'Hawaii', abbr: 'HI', state_id: 13 },
	{ name: 'Idaho', abbr: 'ID', state_id: 14 },
	{ name: 'Illinois', abbr: 'IL', state_id: 15 },
	{ name: 'Indiana', abbr: 'IN', state_id: 16 },
	{ name: 'Iowa', abbr: 'IA', state_id: 17 },
	{ name: 'Kansas', abbr: 'KS', state_id: 18 },
	{ name: 'Kentucky', abbr: 'KY', state_id: 19 },
	{ name: 'Louisiana', abbr: 'LA', state_id: 20 },
	{ name: 'Maine', abbr: 'ME', state_id: 21 },
	{ name: 'Maryland', abbr: 'MD', state_id: 22 },
	{ name: 'Massachusetts', abbr: 'MA', state_id: 23 },
	{ name: 'Michigan', abbr: 'MI', state_id: 24 },
	{ name: 'Minnesota', abbr: 'MN', state_id: 25 },
	{ name: 'Mississippi', abbr: 'MS', state_id: 26 },
	{ name: 'Missouri', abbr: 'MO', state_id: 27 },
	{ name: 'Montana', abbr: 'MT', state_id: 28 },
	{ name: 'Nebraska', abbr: 'NE', state_id: 29 },
	{ name: 'Nevada', abbr: 'NV', state_id: 30 },
	{ name: 'New Hampshire', abbr: 'NH', state_id: 31 },
	{ name: 'New Jersey', abbr: 'NJ', state_id: 32 },
	{ name: 'New Mexico', abbr: 'NM', state_id: 33 },
	{ name: 'New York', abbr: 'NY', state_id: 34 },
	{ name: 'North Carolina', abbr: 'NC', state_id: 35 },
	{ name: 'North Dakota', abbr: 'ND', state_id: 36 },
	{ name: 'Ohio', abbr: 'OH', state_id: 37 },
	{ name: 'Oklahoma', abbr: 'OK', state_id: 38 },
	{ name: 'Oregon', abbr: 'OR', state_id: 39 },
	{ name: 'Pennsylvania', abbr: 'PA', state_id: 40 },
	{ name: 'Puerto Rico', abbr: 'PR', state_id: 41 },
	{ name: 'Rhode Island', abbr: 'RI', state_id: 42 },
	{ name: 'South Carolina', abbr: 'SC', state_id: 43 },
	{ name: 'South Dakota', abbr: 'SD', state_id: 44 },
	{ name: 'Tennessee', abbr: 'TN', state_id: 45 },
	{ name: 'Texas', abbr: 'TX', state_id: 46 },
	{ name: 'Utah', abbr: 'UT', state_id: 47 },
	{ name: 'Vermont', abbr: 'VT', state_id: 48 },
	{ name: 'Virginia', abbr: 'VA', state_id: 49 },
	{ name: 'Washington', abbr: 'WA', state_id: 50 },
	{ name: 'West Virginia', abbr: 'WV', state_id: 51 },
	{ name: 'Wisconsin', abbr: 'WI', state_id: 52 },
	{ name: 'Wyoming', abbr: 'WY', state_id: 53 },
	{ name: 'District of Columbia', abbr: 'DC', state_id: 54 }
]

const BOOKING = {
	MILITARY_RELATIONSHIP_OPTIONS: ['Self', 'Spouse', 'Dependent'],
	REFERENCES: [
		'Search (Google, Safari , Bing)',
		'Maps (Google, Apple)',
		'Yelp',
		'Social Media',
		'Digital Billboard',
		'Flyers, Mailers, Digital flyer',
		'Referral by friend or family',
		'Other'
	],
	PLAN_TO_STAY: [
		'About 1 month',
		'A few months',
		'About 1 year',
		'Indefinitely'
	],
	RESERVATION_TYPE: [
		{ id: 1, type: 'Personal' },
		{ id: 2, type: 'Student' },
		{ id: 3, type: 'Business' }
	],
	STORAGE_CATEGORIES: [
		{ id: 1, name: 'appliances', icon: <CookingPot size={24} /> },
		{ id: 2, name: 'business-inventory', icon: <Buildings size={24} /> },
		{ id: 3, name: 'clothing-and-textiles', icon: <TShirt size={24} /> },
		{ id: 4, name: 'documents-and-records', icon: <Archive size={24} /> },
		{ id: 5, name: 'furniture', icon: <Armchair size={24} /> },
		{ id: 6, name: 'household-goods', icon: <HouseLine size={24} /> },
		{ id: 7, name: 'seasonal-items', icon: <RainbowCloud size={24} /> },
		{ id: 8, name: 'sporting-equipment', icon: <PersonSimpleBike size={24} /> }
	]
}

const DATE = {
	LOCALE: 'en-US',
	TIMEZONE: { timeZone: 'utc' }
}

const INVOICE = {
	TYPE: {
		CREDIT: 0,
		DEBIT: 1
	},
	STATUS: {
		0: {
			id: 0,
			name: 'PENDING',
			name_desc: 'SCHEDULED'
		},
		1: {
			id: 1,
			name: 'PROCESSED',
			name_desc: 'PAID'
		},
		2: {
			id: 2,
			name: 'REJECTED',
			name_desc: 'LATE'
		},
		3: {
			id: 3,
			name: 'CANCELED',
			name_desc: 'CANCELED'
		},
		4: {
			id: 4,
			name: 'REFUNDED',
			name_desc: 'REFUNDED'
		}
	},
	CATEGORY: {
		MOVEIN: 0,
		RENEWAL: 1,
		RENEWAL_WITH_LATE_FEE: 2,
		AUCTION: 3,
		FEES: 4
	}
}

const TRANSLATIONS = ['index', 'booking', 'common', 'dashboard']

const ADMIN_DASHBOARD_ROUTES = [
	{
		path: '/dashboard/admin',
		image: '/house.svg',
		title: 'Dashboard'
	},
	{
		path: '/dashboard/admin/members',
		image: '/members.svg',
		title: 'Members'
	},
	{
		path: '/dashboard/admin/facilities',
		image: '/building.svg',
		title: 'Locations'
	},
	{
		path: '/dashboard/admin/marketing',
		image: '/megaphone.svg',
		title: 'Marketing'
	},
	{
		path: '/dashboard/admin/digital-key/unlock',
		image: '/key-white.svg',
		title: 'Digital keys'
	},
	{
		path: '/dashboard/admin/sales-tool',
		image: '/handshake.svg',
		title: 'Sales tool'
	}
]

const MEMBER = {
	STATUS: {
		2: {
			id: 2,
			name: 'CONVERTED',
			color: palette.primary['bright-grey'],
			textColor: palette.primary['dark-blue']
		},
		3: {
			id: 3,
			name: 'CANCELLED - PRE MOVE IN',
			color: palette.primary['slate-blue'],
			textColor: palette.primary['bright-grey']
		},
		4: {
			id: 4,
			name: 'CANCELLED - PENDING MOVE OUT',
			color: palette.primary['slate-blue'],
			textColor: palette.primary['bright-grey']
		},
		5: {
			id: 5,
			name: 'TERMINATED',
			color: palette.primary['dark-blue'],
			textColor: palette.primary['bright-grey']
		},
		6: {
			id: 6,
			name: 'PENDING EVICTION',
			color: palette.primary['dark-blue'],
			textColor: palette.primary['bright-grey']
		},
		7: {
			id: 7,
			name: 'EVICTED',
			color: palette.primary['dark-blue'],
			textColor: palette.primary['bright-grey']
		},
		8: {
			id: 8,
			name: 'AUCTIONED',
			color: palette.primary['dark-blue'],
			textColor: palette.primary['bright-grey']
		},
		9: {
			id: 9,
			name: 'APPROVAL PENDING',
			color: palette.primary['yellow'],
			textColor: palette.primary['black']
		},
		10: {
			id: 10,
			name: 'APPROVAL DENIED',
			color: palette.primary['red'],
			textColor: palette.primary['bright-grey']
		},
		11: {
			id: 11,
			name: 'SECONDARY ACCOUNT ACCESS',
			color: palette.primary['charcoal-light'],
			textColor: palette.primary['bright-grey']
		},
		12: {
			id: 12,
			name: 'CONVERTED NEIGHBOR',
			color: palette.primary['neighbor-blue'],
			textColor: palette.primary['bright-grey']
		},
		13: {
			id: 13,
			name: 'MAINTENANCE ACCESS',
			color: palette.primary['dark-grey'],
			textColor: palette.primary['white']
		},
		14: {
			id: 14,
			name: 'LIEN NOTICE',
			color: palette.primary['orange'],
			textColor: palette.primary['bright-grey']
		}
	}
}

const SEO = {
	DESCRIPTION:
		"Looking for a self storage unit? Look no further than Stuf Storage. We're nearby making it the perfect location for residents and businesses looking for a clean, safe, and secure storage facility. We offer affordable self storage rentals and a variety of unit sizes to suit your needs!\nStuf is a next-generation self storage company. We partner with real estate owners to convert underutilized space in commercial buildings into secure, inviting, tech-enabled storage powered by hospitality-inspired service. As people and businesses demand more flexibility, Stuf enables its members to extend the boundaries of home and work right in their own neighborhoods."
}

const LEDGER_ITEM = {
	STATUS: {
		PENDING: 0,
		PROCESSED: 1,
		REJECTED: 2,
		CANCELLED: 3,
		REFUNDED: 4
	},
	TYPE: {
		CREDIT: 0,
		DEBIT: 1
	},
	CATEGORY: {
		CREDIT: {
			1: 'Poor Move-in Experience',
			2: 'Anniversaries',
			3: 'Returning Handcart',
			4: 'Recycling Lock',
			5: 'Promotion',
			6: 'Security Alerts (Facility Maintenance)',
			7: 'Other'
		},
		DEBIT: {
			8: 'Late Fee',
			9: 'Preliminary Lien Notice',
			10: 'Notice of Lien Sale / Default Notice',
			11: 'Lock Cut Fee',
			12: 'Lock Replacement',
			13: 'Lien Sale Cancellation Fee',
			14: 'After Hours Response Fee',
			15: 'Damage to Property',
			16: 'Littering',
			25: 'Upgrade Fee',
			7: 'Other'
		}
	}
}

const PERSON = {
	ONBOARD_STATUS: {
		ADMIN: 0,
		ACTIVE_CONTRACT: 1,
		PENDING_APPROVAL: 2,
		NO_ACTIVE_CONTRACTS: 3
	}
}

const NOTIFICATION = {
	TYPE: {
		EMAIL: 0
	},
	CATEGORY: {
		MEMBER_TERMINATION_PROMO_OFFER: 0
	}
}

export {
	CONTRACT,
	firebaseEventTypes,
	firebaseEvents,
	UNIT,
	FACILITY,
	companyInfo,
	HEADER,
	SORT,
	ICON_MAP,
	FAQ,
	STYLES,
	PROMOTIONS,
	DISCOUNT,
	ACCESS,
	ORG,
	STATES,
	BOOKING,
	DATE,
	INVOICE,
	TRANSLATIONS,
	ADMIN_DASHBOARD_ROUTES,
	MEMBER,
	SEO,
	LEDGER_ITEM,
	PERSON,
	NOTIFICATION
}
